import * as React from 'react';
import { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import {
    useRecordContext,
    useListContext,
    useNotify,
    useGetIdentity,
    FileInput, FileField,
    Form,
    CreateBase,
    TextInput,
    required, 
    DateTimeInput,
    ReferenceInput,
    AutocompleteInput,
    useDataProvider,
    BooleanInput,
    SelectInput
} from 'react-admin';
import { Box, Grid, Button } from '@mui/material';
import { useFormContext } from "react-hook-form";

export const NewHistoryComment = ({ reference }) => {
    const record = useRecordContext();
    const { refetch } = useListContext();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const [selectedManager, setSelectedManager] = useState(localStorage.getItem('id'));
    const ref = useRef();

    const handleManagerChange = async (id) => {
        if (id) {
            const recordFromDatabase = await dataProvider.getOne('sales', { id });
            setSelectedManager(recordFromDatabase.data);
        } else {
            setSelectedManager({});
        }
    };

    if (!record || !identity) return null;

    return (
        <Box>
            <CreateBase resource="requestsHistory"
                redirect={false}
                mutationMode="optimistic"
                mutationOptions={{
                    onSuccess: () => refetch()
                }}
            >
                <Form>
                    <HistoryCommentForm
                        selectedManager={selectedManager}
                        handleManagerChange={handleManagerChange}
                        ref={ref}
                        notify={notify}
                        record={record}
                        reference={reference}
                        dataProvider={dataProvider}
                    />
                </Form>
            </CreateBase>
        </Box>
    );
};

const HistoryCommentForm = forwardRef(({ selectedManager, handleManagerChange, notify, record, reference, dataProvider }, ref) => {
    const { 
        reset, 
        setValue, 
        handleSubmit, 
        formState: { errors },
        watch
    } = useFormContext();
    const { refetch } = useListContext();

    useImperativeHandle(ref, () => ({
        resetForm() {
            setValue('comment', '');
            setValue('files', null);
            setValue('date_time', null);
            setValue('important', false);
            setValue('task_manager_id', localStorage.getItem('id'));
        }
    }));

    useEffect(() => {
        handleManagerChange(localStorage.getItem('id'));
    }, []); 

    const validateForm = (isTask) => {
        const formValues = watch();
        if (isTask) {
            if (!formValues.comment) return 'Текст задачи обязателен';
            if (!formValues.date_time) return 'Дата выполнения обязательна';
            if (!formValues.task_manager_id) return 'Ответственный менеджер обязателен';
            if (!formValues.task_type) return 'Тип задачи обязателен';
        } else {
            if (!formValues.comment) return 'Текст комментария обязателен';
        }
        return null;
    };

    const submitForm = (isTask) => {
        const validationError = validateForm(isTask);
        if (validationError) {
            notify(validationError, { type: 'error' });
            return;
        }

        handleSubmit(async (data) => {
            try {
                const transformData = {
                    ...data,
                    ap_id: localStorage.getItem('id'),
                    ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                    request_id: reference === "requests" ? record.id : record.request_id,
                    request_number: reference === "requests" ? record.number : record.request_number,
                    created_at: new Date(),
                    contact_name: record.name,
                    comment_type: isTask ? 'task' : 'comment'
                };

                if (isTask) {
                    transformData.task_done = false;
                    const manager = await dataProvider.getOne('sales', { id: data.task_manager_id });
                    transformData.manager_name = `${manager.data.first_name} ${manager.data.last_name}`;
                } else {
                    transformData.date_time = null;
                    transformData.important = false;
                    transformData.task_manager_id = null;
                    transformData.task_type = null;
                }

                await dataProvider.create('requestsHistory', { data: transformData });
                notify(isTask ? 'Задача добавлена' : 'Комментарий добавлен', { type: 'info' });
                reset();
                refetch();
            } catch (error) {
                console.error('Error submitting form:', error);
                notify('Ошибка при сохранении', { type: 'error' });
            }
        })();
    };

    return (
        <Box mt={1}>
            <TextInput
                label="Текст"
                fullWidth
                multiline
                source="comment"
                rows={2}
                validate={required()}
            />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                                <Box display="flex" flexDirection="row" gap={2} flexWrap="wrap">
                                    <Box flex={1}>
                                        <DateTimeInput label="Назначить на" source="date_time" fullWidth />
                                    </Box>
                                    <Box flex={1}>
                                        <BooleanInput source="important" label="Важная задача" fullWidth />
                                    </Box>
                                </Box>
                    </Box>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <FileInput 
                        source="files" 
                        label="Прикрепите файл (необязательно)" 
                        helperText="Не более 200мб" 
                        multiple={true} 
                        maxSize={200000000}
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                </Grid>
            </Grid>

            <Box display="flex" gap={2}>
                        <ReferenceInput 
                            source="task_manager_id" 
                            reference="sales" 
                            filter={{ 'sales_id_for_tasks': localStorage.getItem('id') }}
                        >
                            <AutocompleteInput 
                                label="Ответственный менеджер*" 
                                onChange={handleManagerChange}  
                                defaultValue={selectedManager?.id} 
                                optionText={(choice) => `${choice.first_name} ${choice.last_name}`} 
                                fullWidth
                            />
                        </ReferenceInput>
                        <ReferenceInput source="task_type" reference="taskTypes">
                            <SelectInput 
                                label="Тип задачи*" 
                                optionText="name" 
                                optionValue="name"
                                defaultValue="Звонок" 
                                fullWidth 
                            />
                        </ReferenceInput>
            </Box>

            <Box display="flex" gap={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => submitForm(true)}
                    fullWidth
                >
                    + Задача
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => submitForm(false)}
                    fullWidth
                >
                    + Комментарий
                </Button>
            </Box>
        </Box>
    );
});
/*import * as React from 'react';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
    useRecordContext,
    useListContext,
    useNotify,
    useGetIdentity,
    useResourceContext,
    FileInput, FileField,
    Form,
    CreateBase,
    TextInput,
    required, 
    DateTimeInput,
    ReferenceInput,
    AutocompleteInput,
    useDataProvider,
    BooleanInput,
    SelectInput
} from 'react-admin';
import { Box, Grid, Button } from '@mui/material';
import { useFormContext } from "react-hook-form";

export const NewHistoryComment = ({ reference }) => {
    const record = useRecordContext();
    const { refetch } = useListContext();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const [selectedManager, setSelectedManager] = useState(localStorage.getItem('id'));
    const ref = useRef();

    const handleManagerChange = async (id) => {
        if (id) {
            const recordFromDatabase = await dataProvider.getOne('sales', { id });
            setSelectedManager(recordFromDatabase.data);
        } else {
            setSelectedManager({});
        }
    };

    if (!record || !identity) return null;

    return (
        <Box>
            <CreateBase resource="requestsHistory"
                redirect={false}
                mutationMode="optimistic"
                mutationOptions={{
                    onSuccess: () => refetch()
                }}
            >
                <Form>
                    <HistoryCommentForm
                        selectedManager={selectedManager}
                        handleManagerChange={handleManagerChange}
                        ref={ref}
                        notify={notify}
                        record={record}
                        reference={reference}
                        dataProvider={dataProvider}
                    />
                </Form>
            </CreateBase>
        </Box>
    );
};

const HistoryCommentForm = forwardRef(({ selectedManager, handleManagerChange, notify, record, reference, dataProvider }, ref) => {
    const { reset, setValue, handleSubmit } = useFormContext();
    const { refetch } = useListContext();

    useImperativeHandle(ref, () => ({
        resetForm() {
            setValue('comment', '');
            setValue('files', null);
            setValue('date_time', null);
            setValue('important', false);
            setValue('task_manager_id', localStorage.getItem('id'));
            setValue('task_type', null);
        }
    }));

    const submitForm = (isTask) => {
        handleSubmit(async (data) => {
            try {
                const transformData = {
                    ...data,
                    ap_id: localStorage.getItem('id'),
                    ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                    request_id: reference === "requests" ? record.id : record.request_id,
                    request_number: reference === "requests" ? record.number : record.request_number,
                    created_at: new Date(),
                    contact_name: record.name,
                    comment_type: isTask ? 'task' : 'comment'
                };

                if (isTask) {
                    transformData.task_done = false;
                    if (data.task_manager_id) {
                        const manager = await dataProvider.getOne('sales', { id: data.task_manager_id });
                        transformData.manager_name = `${manager.data.first_name} ${manager.data.last_name}`;
                    }
                } else {
                    // Очищаем поля задачи при отправке комментария
                    transformData.date_time = null;
                    transformData.important = false;
                    transformData.task_manager_id = null;
                    transformData.task_type = null;
                }

                await dataProvider.create('requestsHistory', { data: transformData });
                notify(isTask ? 'Задача добавлена' : 'Комментарий добавлен', { type: 'info' });
                reset();
                refetch();
            } catch (error) {
                console.error('Error submitting form:', error);
                notify('Ошибка при сохранении', { type: 'error' });
            }
        })();
    };

    return (
        <Box mt={1}>
            <TextInput
                label="Текст"
                fullWidth
                multiline
                source="comment"
                rows={2}
                validate={required()}
            />

            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={6}>
                    <FileInput 
                        source="files" 
                        label="Прикрепите файл" 
                        helperText="Не более 200мб" 
                        multiple={true} 
                        maxSize={200000000}
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <DateTimeInput 
                            label="Дата выполнения" 
                            source="date_time" 
                            fullWidth 
                        />
                        <BooleanInput 
                            source="important" 
                            label="Важная задача" 
                            fullWidth 
                        />
                        <ReferenceInput 
                            source="task_manager_id" 
                            reference="sales" 
                            filter={{ 'sales_id_for_tasks': localStorage.getItem('id') }}
                        >
                            <AutocompleteInput 
                                label="Ответственный" 
                                onChange={handleManagerChange}  
                                defaultValue={selectedManager?.id} 
                                optionText={(choice) => `${choice.first_name} ${choice.last_name}`} 
                                fullWidth
                            />
                        </ReferenceInput>
                        <ReferenceInput source="task_type" reference="taskTypes">
                            <SelectInput 
                                label="Тип задачи" 
                                optionText="name" 
                                optionValue="name" 
                                fullWidth 
                            />
                        </ReferenceInput>
                    </Box>
                </Grid>
            </Grid>

            <Box display="flex" gap={2} mt={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => submitForm(false)}
                    fullWidth
                >
                    Добавить комментарий
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => submitForm(true)}
                    fullWidth
                >
                    Добавить задачу
                </Button>
            </Box>
        </Box>
    );
});*/

/*import * as React from 'react';
import { useState, useEffect, forwardRef, useImperativeHandle, useRef} from 'react';
import {
    useRecordContext,
    useListContext,
    useCreate,
    useUpdate,
    useNotify,
    useGetIdentity,
    Identifier,
    useResourceContext,
    FileInput, FileField,
    Form,
    CreateParams,
    UpdateParams,
    DataProvider,
    fetchUtils,
    CreateBase,
    SaveButton,
    SimpleForm,
    useCreateContext,
    Toolbar,
    TextInput,
    SelectInput,
    required, 
    DateTimeInput,
    ReferenceInput,
    AutocompleteInput,
    useDataProvider,
    useRedirect,
    useRefresh,
    BooleanInput
} from 'react-admin';
import { Box, TextField, Button, Grid, Typography  } from '@mui/material';
import { types } from './types';
import { StatusSelector } from '../misc/StatusSelector';
import {useFormContext} from "react-hook-form";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export const NewHistoryComment = ({reference}) => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const { refetch } = useListContext();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const [selectedCommentType, setSelectedCommentType] = useState(types[0].id);
    const [selectedManager, setSelectedManager] = useState(localStorage.getItem('id'));
    const ref = useRef();

    const handleCommentTypeChange = (event) => {
        setSelectedCommentType(event.target.value);
    };

	const handleManagerChange = async(id) => {
		if(id){
			var recordFromDatabase = await dataProvider.getOne('sales', { id });
			setSelectedManager(recordFromDatabase.data);
		}else{
			setSelectedManager({});
		}
	};

    if (!record || !identity) return null;

    if(selectedCommentType === 'task' || selectedCommentType === 'comment'){
    return (
        <Box>
            <CreateBase resource="requestsHistory"
                redirect={false}
                mutationMode="optimistic"
                transform={(data) => {
                    if(selectedCommentType === 'task'){
                        return ({
                            ...data,
                            ap_id: localStorage.getItem('id'),
                            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                            request_id: reference === "requests" ? record.id : record.request_id, 
                            request_number: reference === "requests" ? record.number : record.request_number,
                            created_at: new Date(),
                            task_done: false,
                            manager_name: selectedManager.first_name + " " + selectedManager.last_name,
                            contact_name: record.name,
                        });
                    }else{
                        return ({
                            ...data,
                            ap_id: localStorage.getItem('id'),
                            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                            request_id: reference === "requests" ? record.id : record.request_id, 
                            request_number: reference === "requests" ? record.number : record.request_number,
                            created_at: new Date(),
                            contact_name: record.name,
                        });
                    }
                }}
                mutationOptions={{
                    onSuccess: () => {
                        try{
                           refetch();
                           notify(selectedCommentType !== 'task' ? 'Комментарий добавлен' : 'Задача добавлена', { type: 'info', undoable: false });
                           ref.current.resetForm();
                        }catch(err){console.log(err)}
                    }}
                }
            > 
            <Form>
            <HistoryCommentForm 
                selectedCommentType={selectedCommentType} 
                handleCommentTypeChange={handleCommentTypeChange} 
                selectedManager={selectedManager} 
                handleManagerChange={handleManagerChange} 
                ref={ref}
            />
            </Form>
            </CreateBase>
        </Box>
    );
    }

    if(selectedCommentType === 'message'){
    return (
        <Box>
            <CreateBase resource="requestsHistory"
                redirect={false}
                mutationMode="optimistic"
                transform={(data) => {
                        return ({
                            ...data,
                            ap_id: localStorage.getItem('id'),
                            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                            request_id: reference === "requests" ? record.id : record.request_id, 
                            request_number: reference === "requests" ? record.number : record.request_number,
                            created_at: new Date(),
                            contact_name: record.name
                        });
                }}
                mutationOptions={{
                    onSuccess: () => {
                        try{
                           refetch();
                           notify('Сообщение отправлено клиенту', { type: 'info', undoable: false });
                           ref.current.resetForm();
                        }catch(err){console.log(err)}
                    }}
                }
            > 
            <Form>
            <HistoryCommentForm 
                selectedCommentType={selectedCommentType} 
                handleCommentTypeChange={handleCommentTypeChange} 
                selectedManager={selectedManager} 
                handleManagerChange={handleManagerChange} 
                ref={ref}
            />
            </Form>
            </CreateBase>
        </Box>
    );
    }

    return null;
};

const HistoryCommentForm = forwardRef(({ selectedCommentType, handleCommentTypeChange, selectedManager, handleManagerChange }, ref) => {
    const { reset, setValue, watch } = useFormContext();
    const selectedTemplate = watch('template'); // Watch the template field for changes

    useImperativeHandle(ref, () => ({
        resetForm() {
            // Reset specific form fields
            setValue('comment', '');
            setValue('files', '');
            setValue('date_time', '');
        }
    }));
    
    useEffect(() => {
        handleManagerChange(localStorage.getItem('id'));
    }, []); 

    useEffect(() => {
        if (selectedTemplate) {
            setValue('comment', selectedTemplate); // Set the comment field with the selected template text
        } else {
            setValue('comment', ''); // Clear the comment field if no template is selected
        }
    }, [selectedTemplate]); // Re-run this effect when the selected template changes

    if(selectedCommentType === 'task' || selectedCommentType === 'comment'){
        return (
            <Box mt={1}>
                <TextInput
                    label={selectedCommentType !== 'task' ? "Введите текст комментария" : "Введите текст задачи"}
                    fullWidth
                    multiline
                    source="comment"
                    rows={2}
                    validate={required()}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        {selectedCommentType !== 'task' ? 
                            <FileInput source="files" label="Прикрепите файл (если требуется)" helperText="Не более 200мб" multiple={true} maxSize={200000000}>
                                <FileField source="src" title="title" />
                            </FileInput> :
                            <Box flex="1" flexDirection="column">
                                <Box display="flex" flexDirection="row" gap={2} flexWrap="wrap">
                                    <Box flex={1}>
                                        <DateTimeInput label="Назначить на" source="date_time" validate={required()} fullWidth />
                                    </Box>
                                    <Box flex={1}>
                                        <BooleanInput source="important" label="Важная задача" fullWidth />
                                    </Box>
                                </Box>
                                <ReferenceInput source="task_manager_id" reference="sales" filter={{ 'sales_id_for_tasks': localStorage.getItem('id') }}>
                                    <AutocompleteInput label="Менеджер" onChange={handleManagerChange}  defaultValue={selectedManager?.id} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} fullWidth validate={required()} />
                                </ReferenceInput>
                            </Box>
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box flex="1" flexDirection="column">
                            <SelectInput
                                label="Тип"
                                helperText={selectedCommentType === 'task' ? `Здесь можно выбрать "Комментарий" или "Сообщение"` : `Здесь можно выбрать "Задача" или "Сообщение"`}
                                source="comment_type"
                                choices={types}
                                defaultValue={types[0].id}
                                validate={required()}
                                fullWidth 
                                onChange={handleCommentTypeChange}
                            />
                            {selectedCommentType === 'task' ? 
                                <ReferenceInput source="task_type" reference="taskTypes" >
                                    <SelectInput label="Тип задачи" optionText="name" optionValue="name" fullWidth validate={required()} />
                                </ReferenceInput> 
                                : null 
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Box display="flex">
                    <SaveButton 
                        label={selectedCommentType !== 'task' ? "Добавить комментарий" : "Добавить задачу"} 
                        icon={false}
                        redirect={false} 
                        submitOnEnter={false}
                    />
                </Box>     
            </Box>
        );
    }

    if(selectedCommentType === 'message'){
        return (
            <Box>
                <TextInput
                    label={"Введите текст сообщения (или выберите шаблон)"}
                    fullWidth
                    multiline
                    source="comment"
                    validate={required()}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <ReferenceInput source="template" reference="messagesTemplates" >
                            <SelectInput label="Шаблоны сообщений" optionText="name" optionValue="text" fullWidth />
                        </ReferenceInput> 
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box flex="1" flexDirection="column">
                            <SelectInput
                                label="Тип"
                                helperText={`Здесь можно выбрать "Комментарий" или "Задача"`}
                                source="comment_type"
                                choices={types}
                                defaultValue={types[0].id}
                                validate={required()}
                                fullWidth 
                                onChange={handleCommentTypeChange}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box display="flex">
                    <SaveButton 
                        label={"Отправить сообщение клиенту"} 
                        icon={false}
                        redirect={false} 
                        submitOnEnter={false}
                    />
                </Box>     
            </Box>
        );
    }

    return null;
});*/


/*const HistoryCommentForm = forwardRef(({ selectedCommentType, handleCommentTypeChange, selectedManager, handleManagerChange }, ref) => {
    const { reset, setValue } = useFormContext();

    useImperativeHandle(ref, () => ({
        resetForm() {
            //reset();
            setValue('comment', '');
            setValue('file', '');
            setValue('date_time', '');
        }
    }));
    
    useEffect(() => {
        handleManagerChange(localStorage.getItem('id'));
    }, []); 

    if(selectedCommentType === 'task' || selectedCommentType === 'comment'){
    return (
        <Box>
            <TextInput
                label={selectedCommentType !== 'task' ? "Введите текст комментария" : "Введите текст задачи"}
                fullWidth
                multiline
                source="comment"
                rows={2}
                validate={required()}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    {selectedCommentType !== 'task' ? 
                        <FileInput sx={{'& .RaFileInput-dropZone':{
                            //height: '2rem'
                        }}} source="file" label="Прикрепите файл (если требуется)" helperText="Не более 100мб" multiple={false} maxSize={100000000}>
                            <FileField source="src" title="title" />
                        </FileInput> :
                        <Box flex="1" flexDirection="column">
                            <DateTimeInput label="Назначить на" source="date_time" validate={required()} />
                            <ReferenceInput source="task_manager_id" reference="sales" filter={{ 'sales_id_for_tasks': localStorage.getItem('id') }}>
                                <AutocompleteInput label="Менеджер" onChange={handleManagerChange}  defaultValue={selectedManager.id} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} fullWidth validate={required()} />
                            </ReferenceInput>
                        </Box>
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box flex="1" flexDirection="column">
                        <SelectInput
                            label="Тип"
                            helperText={selectedCommentType === 'task' ? `Здесь можно выбрать "Комментарий" или "Сообщение"` : `Здесь можно выбрать "Задача" или "Сообщение"`}
                            source="comment_type"
                            choices={types}
                            defaultValue={types[0].id}
                            validate={required()}
                            fullWidth 
                            onChange={handleCommentTypeChange}
                        />
                        {selectedCommentType === 'task' ? 
                            <ReferenceInput source="task_type" reference="taskTypes" >
                                <SelectInput label="Тип задачи" optionText="name" optionValue="name" fullWidth validate={required()} />
                            </ReferenceInput> 
                            : null 
                        }
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex">
                <SaveButton 
                    label={selectedCommentType !== 'task' ? "Добавить комментарий" : "Добавить задачу"} 
                    icon={false}
                    redirect={false} 
                    submitOnEnter={false}
                />
            </Box>     
        </Box>
    );
    }

    if(selectedCommentType === 'message'){
    return (
        <Box>
            <TextInput
                label={"Введите текст сообщения (или выберите шаблон)"}
                fullWidth
                multiline
                source="comment"
                rows={2}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <ReferenceInput source="template" reference="messagesTemplates" >
                        <SelectInput label="Шаблоны сообщений" optionText="name" optionValue="text" fullWidth />
                    </ReferenceInput> 
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box flex="1" flexDirection="column">
                        <SelectInput
                            label="Тип"
                            helperText={`Здесь можно выбрать "Комментарий" или "Задача"`}
                            source="comment_type"
                            choices={types}
                            defaultValue={types[0].id}
                            validate={required()}
                            fullWidth 
                            onChange={handleCommentTypeChange}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex">
                <SaveButton 
                    label={"Отправить сообщение клиенту"} 
                    icon={false}
                    redirect={false} 
                    submitOnEnter={false}
                />
            </Box>     
        </Box>
    );
    }

    return null;
});*/